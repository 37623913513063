  .scroll{
    font: normal normal 800 30px/49px Montserrat;
    letter-spacing: 0px;
    color: white;
    opacity: 1;
    padding-right: 10;
  }  
  

  @media (min-width: 901px) and (max-width: 1171px) {
    .scroll {
      position: relative;
      top: 110px; /* Move the h1 element down by 60 pixels */
      color: grey; /* Change text color to black for screens less than 900px */
    }
  }

  @media (min-width: 601px) and (max-width: 901px) {
    .scroll {
      position: relative;
      top: 80px; /* Move the h1 element down by 60 pixels */
      color: grey; /* Change text color to black for screens less than 900px */
    }
  }
  @media (min-width: 451px) and (max-width: 600px) {
    .scroll {
      position: relative;
      top: 50px; /* Move the h1 element down by 60 pixels */
      color: grey; /* Change text color to black for screens less than 900px */
    }
  }
  @media (max-width: 451px) {
    .scroll {
      font-size: 25px;
      position: relative;
      top: 40px; /* Move the h1 element down by 60 pixels */
      color: grey; /* Change text color to black for screens less than 900px */
    }
  }
  h1 {
    font-size: 30px;
    color: "#FFFFFF";
    opacity: 1;
    padding-right: 10;
    font: normal normal 800 30px/49px Montserrat;
  }
  
  .text_1 {
    animation: text1;
  }
  
  .text_2 {
    animation: text2;
  }
  .text_3 {
    animation: text3;
  }
  
  .text_1, .text_2, .text_3 {
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    animation-duration: 15s;
    animation-timing-function: steps(30, end);  
    animation-iteration-count: infinite;
  }
  
  .text_1::after, .text_2::after , .text_3::after{
    content: " | ";
    position: absolute;
    right: 0;
    animation: caret infinite;
    animation-duration: 1s;
    animation-timing-function: steps(1, end);
  }
  @keyframes text3 {
    0%, 50%, 100% {
      width: 0;
    }
    
    60%, 90% {
      width: 14em;
    }
  }

  @keyframes text2 {
    0%, 50%, 100% {
      width: 0;
    }
    
    60%, 90% {
      width: 14em;
    }
  }
  
  @keyframes text1 {
    0%, 50%, 100% {
      width: 0;
    }
    10%, 40% {
      width: 14em;
    }
  }
  
  @keyframes caret {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }