nav {
  height: 10vh;
}

.nav-des-top {
  background-color: rgb(255, 255, 255);
  --nav-col: black;
}

.nav-des-free {
  background-color: rgba(255, 255, 255, 0.842);
  --nav-col: black;
}

.navbar .navbar-nav .nav-link {
  padding: 1vh 1.8vw;
  font-size: 1.2vw;
  color: var(--nav-col);
}
.navbar .nav-item{
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .navbar-nav .nav-link:hover {
  color: #ce19c9;
  position: relative;
}

.navbar .navbar-nav .nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #ce19c9, #da544f);
  transition: width 0.3s;
}

.navbar .navbar-nav .nav-link:hover::after {
  width: 50%;
}

.navbar .navbar-nav .nav-link:focus {
  font-weight: bold;
  color: #da544f;
}

.navbar .navbar-nav .navbar-login {
  margin: auto 2em;
  color: red;
  border: 2px solid red;
  padding: 0.5em 1em;
  border-radius: 5px;
  transform: translateY(-15%);
}
.navbar .navbar-nav .navbar-login:hover {
  color: white;
  border: 2.5px solid red;
  background-color: red;
}

.navbar .navbar-nav .navbar-login::after {
  display: none;
}

.bits-logo-img {
  height: 13vh;
  width: 15vw;
  min-width: 150px;
  margin-right: 0.2em;
  margin-top: 7px;
  transition: 0.2s ease;
}

#main-nav .contaier-fluid {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 1080px) {
  #navbarSupportedContent {
    width: 100%;
    display: flex;
    justify-content: flex-end; 
  }
}

@media only screen and (max-width: 993px) {
  .navbar {
    background-color: white;
  }
  .bits-logo-img{
    margin-top: -5px;
    
    
  }

  nav {
    height: 80px;
    
  }

  .navbar .navbar-nav .nav-link {
    color: black;
    padding: 0.5em 1em;
    font-size: 20px;

  }
  .topper{
    margin-top: 40px;
  }
  .navbar .navbar-nav .navbar-login {
    margin: 0;
  }

  /* Slide-in from the right animation for the dropdown menu */
  .navbar-side-collapse-right {
    position: fixed;
    top: 0;
    right: -100%; /* Move the dropdown to the right side, initially hidden */
    width: 50%; /* Adjust the width to your preference */
    height: 100%; /* Make the dropdown take the full height of the screen */
    padding: 1em;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(206, 6, 6, 0.2);
    transition: right 0.3s ease;
    white-space: nowrap;
    z-index: 9999; /* Ensure the dropdown is above other elements */
  }

  .navbar-side-collapse-right.show {
    right: 0; /* Show the dropdown by moving it to the right */
  }

  .navbar-side-collapse-right ul {
    flex-direction: column;
  }
}



